

$y: #edd300;
$y-hover: #f4e677;
$w: #fff;
$b: #0f0f0f;
$g-0: #2f2f2f;
$g-1: #313031;
$g-2: #424242;
$g-3: #5f5f5f;
$g-4: #737373;
$g-5: #cccccc;
$g-6: #ebebec;
$g-hover: #757474;
$disabled: #a7a7a6;
$background-modal:#c4c4c47c;

$CardMessage: #cccccc;
$CardSuccess: #2ecc71;
$CardError: #e14d45;
$CardInfo: #3c90d1;
$CardWarning: #ed991d;

$ModalDeleteBackgroundColor: #514b4b80;

$hover-button-menu:#f5f5f5;
$BackgroundColorSection: #f5f5f5;

$ButtonsActionsTableBackgroundColor: transparent;
