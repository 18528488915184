@import "../../styles/style";
.section {
  @include widthAndHeight(100%, 100%);

  @include display(center, center);
  
  background-color: rgba(92, 92, 92, 0.63);
  position: absolute;
  left:0px;
  top:0px;
float: center;
  z-index: 100;
  &_modal {
    background-color: #f3f0f0;
    @include flexColumn(space-between, center);
    @include widthAndHeight(50%, 50%);
    min-width: 380px;
    max-width: 800px;
    min-height: 178px;
    max-height: 450px;
    overflow: auto;
    box-shadow: 1px 9px 18px 6px #505050;
    padding: 12px 24px;
    border-radius: 12px;
    &-title {
        width: 100%;
        text-align: start;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}
