@import "../../../../../../../../styles/style";

.form{
    @include flexColumn(flex-start,space-between);
    width: 100%;
    padding: 0px 12px;
    &_content{
        @include widthAndHeight(100%,400px);
    }
    &_row{
    @include form-row();
    gap: 12px;
    &-checkbox{
        margin-top: 10px;
        gap: 12px;
        @include widthAndHeight(600px,45px);
        @include display(space-between,center);
    }}
}