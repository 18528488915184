@import "../../styles/style";

.footerForm,
.footerForm-min {
  @include display(space-around, center);
  margin-top: 8px;
  min-height: 68px;

  .buttons{
 
    @include display(space-between, center);
    width: 420px;
  }
}
.buttons-min{
  @include display(space-between, center);

  width: 310px;

}
.footerForm {
  @include widthAndHeight(450px,"");
  margin: auto;
}

.footerForm-min {
  @include display(flex-end, center);
  
}
