@import "../../../../../../styles/style";
.section {
  @include widthAndHeight(100%, calc(100vh - 220px));
  position: relative;
}

.inputFullWidth,
.fator_options {
  @include widthAndHeight(100%, 80px);
}
.sectionContent {
  @include widthAndHeight(100%, calc(100vh - 80px));
  padding: 0 32px;
  overflow: auto;
  margin: auto;
  &-form {
    @include widthAndHeight(100%, auto);
    @include flexColumn(space-between, flex-start);
    margin-top: calc((60vh - 55vh));
  }
}

.form {
  width: 95%;
  margin: auto;

  &-row-header {
    @include widthAndHeight(50%, 80px);
    @include display(space-around, center);

    margin: auto;

    &-input {
      @include widthAndHeight(70%, auto);
    }
  }
  &-row,
  &-row-product {
    @include display(space-between, flex-start);
    gap: 0 5px;
  }
  &-row {
    @include widthAndHeight(100%, 50px);

    gap: 16px;
  }
  &-row-product {
    width: 100%;
    @include for-notebook {
      @include flexColumn(center, center);
    }
  }
}

.title-form {
  font-size: 3.4rem;
}

.info-form,
.group-checkbox {
  @include display(space-around, center);
  height: 60px;
  gap: 8px;
}

.info-form {
  &-img {
    @include widthAndHeight(26px, 26px);
  }
  &-text {
    @include font(1rem, bold);
  }
}
