@import "../../../../../../styles/style";
.content {
  @include widthAndHeight(100%, calc(100% - 50px));
  overflow: auto;
  padding: 12px 32px;
  &_form {
    @include flexColumn(center, flex-start);

    &-section {
      @include widthAndHeight(40%, 80px);
      gap: 0 20px;
      @include display(space-between, center);
      &-checkbox {
        margin-top: 8px;
      }
    }
  }
  &_header {
    @include display(space-between, center);
    height: 80px;
    &-img {
      background-size: cover;
      @include widthAndHeight(auto, 100%);
      img {
        @include widthAndHeight(auto, 100%);
        background-size: cover;
        max-width: 110px;
        max-height: 90px;
      }
    }
  }
}

.content_row-form {
  @include display(space-between, center);
  gap: 8px;
}
