@import "../../../../../../styles/style";

.content{
  @include widthAndHeight(100%, calc(100% - 50px));
  overflow: auto;
  padding: 0 32px;
}

.section{
  @include display(space-between,"");
  @include widthAndHeight(100%,100%);
  &_form{
    @include widthAndHeight(60%,100%);

  }
  &_parcelas{
    @include widthAndHeight(40%,356px);
    padding: 0 0 0 8px;
    &-header{
      @include display(space-between,center);
      margin-top: 27px;
      background-color: $g-2;
      color: $w;
      padding-left: 8px;
      span{
        font-size: 1rem;
      }
    }
    &-button{
      @include widthAndHeight(180px,40px);
      @include display(space-around,center);
      background-color: $y;
      border: none;
      font-size: 1rem;
      padding: 0 8px;
      img{
        @include widthAndHeight(30px,30px);
        cursor: pointer;
      }
    }
    &-list{
      @include widthAndHeight(100%,90%);
      overflow: auto;

    }
  }
}
.form-row{
  @include display(center,center);
  gap: 8px;
}

.table{
  width: 100%;
  &-head{
    background-color: $g-2;
    color: $w;
  }
  thead{
    width: 100%;
    tr{
      width: 100%;
      th{
        width: 40%;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-align: center;
        
      }
    }
  }
  tbody{
    tr{
      td{
        text-align: center;
        img{
          &:hover{
          cursor: pointer;
          border-radius: 12px;
          background-color: $y-hover;}
        }
      }
    }
  }
}

.row-color{
  background-color: rgb(238, 238, 238);
}

.form_content-parcelas{
  @include widthAndHeight(100%,80%);
  @include flexColumn(space-around,"");
  &-title{
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 4px;
  }
}