@import "../../../styles/style";

.section {
    @include widthAndHeight(95%, 90%);
    overflow-y: auto;
    margin: auto;
  }

  .sectionContent {
    @include flexColumn(center, center);
    width: 100%;
    background-color: $w;
    margin: auto;
    position: relative;
    border-radius: 16px;
    header{
      width: 100%;
    }
  }