@import "../../../../../styles/style";

.userInformation {
  @include widthAndHeight(240px, 160px);
  @include flexColumn(space-between, center);
  position: absolute;
  top: 50px;
  right: 0;
  border: 1px solid $g-5;
  background-color: $w;
  z-index: 200;

  &__name,
  &__version {
    @include font(1.2rem, 300);
    padding: 8px;
    list-style: none;
    border-bottom: 1px solid $g-5;
    width: 100%;
  }

  &__button {
    @include font(0.9rem, 400);
    @include widthAndHeight(60%, auto);
    padding: 8px;
    margin: auto;
    border-radius: 8px;
    background-color: $y;
    border: none;
    transition: all ease-in-out 0.4s;

    &:hover {
      cursor: pointer;
      background-color: $y-hover;
    }
  }
}
