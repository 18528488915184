@import "../../../../../../../styles/style";
@import "../forms.module.scss";

.content {
  padding: 0 12px;
  @include widthAndHeight(100%,200px);
  @include display(flex-start,center);
 

  form{
    @include widthAndHeight(100%,100%);
  }
}
