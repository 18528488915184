@import "./variables";

@mixin width($min, $width, $max) {
  min-width: $min;
  width: $width;
  max-width: $max;
}

@mixin height($min, $height, $max) {
  min-height: $min;
  height: $height;
  max-height: $max;
}

@mixin widthAndHeight($width, $height) {
  width: $width;
  height: $height;
}

@mixin font($size, $weight) {
  font-size: $size;
  font-weight: $weight;
}

@mixin display($justify_content, $align_items) {
  display: flex;
  justify-content: $justify_content;
  align-items: $align_items;
}

@mixin flexColumn($justify_content, $align_items) {
  flex-direction: column;
  @include display($justify_content, $align_items);
}

@mixin grid($columns, $rows, $areas) {
  display: grid;
  grid-template-columns: $columns;
  grid-template-rows: $rows;
  grid-template-areas: $areas;
}

@mixin backgroundImage($url) {
  background-image: url($url);
  background-size: cover;
  background-position: center;
}

@mixin resetFocusInput() {
  border-color: inherit;
  box-shadow: none;
  outline: none;
}

@mixin colors($background, $color) {
  background-color: $background;
  color: $color;
}



@mixin for-tablet {
  @media (max-width: 800) {
    @content;
  }
}

@mixin for-notebook {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin placeholder {
  @include font(1rem, 400);
  color: #807f7f;
}

@mixin form-row {
  @include display(space-between, center);
  gap: 8px;
}



@mixin formRow( $combobox: false) {
  @include display(space-between, center);
  
  @if $combobox {
    width: 160px;
  }
  gap: 12px;
}
