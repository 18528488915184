@import "../../../styles/style";

.header {
  @include widthAndHeight(70%, 100%);
  @include display(space-between, center);
  &_search {
    @include widthAndHeight(98%, 50px);
    @include display(space-between, center);
    background-color: $w;
    border: 1px solid $g-1;
    border-radius: 16px;
    position: relative;
    &-input {
      @include widthAndHeight(100%, 100%);
      &:active,
      &:focus,
      ::after {
        border-radius: 14px;
      }

      div {
        @include resetFocusInput();
        @include widthAndHeight(100%, 100%);
        max-width: none;
        background-color: transparent;
        border: none;
      }
    }
    &-icon {
      @include backgroundImage("../../../assets/icons/gerais/search.svg");
      right: 8px;
    }
    &-clean {
      @include backgroundImage("../../../assets/icons/gerais/close.svg");

      right: 40px;
    }
  }

  &_filter {
    &-button {
      @include widthAndHeight(40px, 40px);
      @include display(center, center);
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 5px;
      margin-right: 10px;

      &:hover {
        border: 1px solid $g-1;
        background-color: transparent;
        cursor: pointer;
      }
    }
    &-icon {
      @include backgroundImage("../../../assets/icons/gerais/filter.png");
      @include widthAndHeight(24px, 24px);
      border: none;
      background-color: transparent;
    }
  }
}

.header_search{
 &-icon,
&-clean {
  position: absolute;
  right: 8px;
  @include widthAndHeight(28px, 28px);

  &:hover {
    cursor: pointer;
    padding: 16px;
  }
}
}