@import "../../styles/style";

@mixin page($size) {
  display: grid;
  @include widthAndHeight(100vw, 100vh);

  grid-template-columns: $size calc(100% - $size);
  grid-template-rows: 100% 100%;
  grid-template-areas: "menu content";
}
.principal_page {
  @include page(220px);
}

.principal_page-min {
  @include page(60px);
}

.principal_page-menu {
  grid-area: menu;
}
.principal_page-section {
  grid-area: content;
}
