@import "../../../../../../../../styles/style";

.form {
 
  padding: 8px 0px;

  &_footer {
    @include display(space-between,center);
    width: 500px;
    margin-top: 8px;
  }
}
