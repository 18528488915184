@import "../../../../../../styles/style";
.content{
    padding: 12px 48px;
    &_form{
        @include flexColumn(center,flex-start);

        &-section{
            @include widthAndHeight(40%,80px);
            gap: 0 20px;
            @include display(space-between,center);
            &-checkbox{
                margin-top: 8px;
            }

        }
    }
}
