@import "../../../../../../../../styles/style";

.content{
    @include widthAndHeight(100%,calc(100% - 50px));
    overflow: auto;
    padding: 0 24px;
 
}

.form_content{
    padding-bottom: 16px;
}

.form-row{
    @include display(space-between,center);
    gap:8px;
}