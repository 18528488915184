@import "../../../../styles/style";

.pagination_list {
  @include widthAndHeight(auto, 80px);
  @include display(space-around, center);
  list-style: none;
  gap: 16px;

  li {
    @include widthAndHeight(40px, 40px);
    @include display(center, center);
    border: 1px solid $g-1;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: $y;
    }
  }
}

.selected {
  background-color: $y;
}
