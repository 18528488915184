@import "./style";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.ms-DetailsRow-fields {
  display: flex;
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
}

.ms-DetailsHeader-cell {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ms-DetailsHeader-cellTitle {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
}

.ms-DetailsHeader {
  padding: 0;
  display: flex;
  justify-content: center;
  white-space: rap;
}

.ms-DetailsHeader-cellName {
  font-size: 1.2rem;
}

.ms-Check {
  opacity: 1;
}

.ms-TextField-fieldGroup {
  height: 40px;
  font-size: 1rem;

  input {
    display: flex;
    align-items: center;
    padding-left: 8px;
    font-size: 1rem;
  }
}
.ms-DatePicker-event--with-label {
  padding: 10px;
}

.ms-ComboBox,
.css-144 {
  height: 40px;
  font-size: 1rem;
}
.ms-ComboBox-container {
  height: 70px;
}

.root-148 {
  height: 60px;
}

.ms-TextField {
  height: 85px;
}

.ms-DetailsRow-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.message-error {
  font-size: 0.9rem;
  font-weight: 400;
  color: rgb(225, 85, 85);
}

.field-152::placeholder {
  color: rgb(112, 112, 112);
}


input[type="checkbox"],input[type="radio"]{
  background-color:rgb(230, 230, 230);
  border: 1px solid #2f2f2f;

}

input[type="checkbox"]:checked,input[type="radio"]:checked {
  background-color: $y;
  accent-color:#edd700;
  cursor: pointer;
  color: white;
  &::after{
    accent-color:#edd700;

  }
}

input[type="radio"]{
border-radius: 50%;
}