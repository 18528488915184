@import "../../../../../../../styles/style";

.section {
  @include widthAndHeight(100%, 92%);
  padding: 0 46px;

  margin: auto;
  overflow: auto;
  &_form {
    width: 100%;
  }

  &_content {
    @include widthAndHeight(100%, auto);
    &-checkbox{
      height: 26px;
    }
    &-box {
      @include widthAndHeight(100%, auto);
      @include display(space-between, flex-start);
      &-form {
        @include widthAndHeight(49%, auto);
      }
    }
  }
}

.middle {
  width: 49%;
}
