@import "../forms.module.scss";
@import "../../../../../../../styles/style";

.content {
  padding: 0 42px;
  @include widthAndHeight(100%, calc(100vh - 50px));
  overflow: auto;

  form {
    width: 100%;
  }
}

.form-row {
  @include formRow();
}
