@import "../../../styles/style";

.content{
  @include display(flex-start, center);
  margin-top: 6px;
  width: 100%;
  height: 100%;
  &-input{
    width: 100%;
  }
}
.button_request,
.button_request-disabled {
  @include widthAndHeight(36px, 40px);
  @include display(center, center);
  margin-top: 9px;
  border: 1px solid $g-4;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
}

.button_request {
  background-color: $y;
  cursor: pointer;
  &:hover {
    background-color: $y-hover;
  }
  &-disabled {
    background-color: $disabled;
  }
}
