@import "../../../../../../../../styles/style";

.form {
  &-content {
    padding: 0 12px;
  }
  &-row {
    @include form-row();
  }
}
