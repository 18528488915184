@import "../../../../../../styles/style";

.content {
  @include widthAndHeight(100%, calc(100% - 50px));
  overflow: auto;
  padding: 0 24px;
  
  &_form {
    &-row {
      @include display(space-between, flex-start);
      width: 100%;
      gap: 0 8px;
      &-group{
        width: 48%;
        @include display(space-between, flex-start);
        gap: 0 8px;

      }
    }
  }
}

.input {
  &-sm {
    width: 40%;
  }
  &-md {
    width: 60%;
  }
  &-full {
    width: 100%;
  }
}
