@import "../../../styles/style";

.headerForm{
  @include display(space-between,center); 
   @include widthAndHeight(100%,100%);

   @include for-notebook{
    @include widthAndHeight(100%,100%);
   }

}
.section {
  @include widthAndHeight(100%,100%);
  @include flexColumn(flex-start, center);
  background-color: $w;
  margin: auto;
  position: relative;
  @include for-notebook{
    overflow-x: auto
  }
}

.table {
  @include widthAndHeight(100%, 100%);
  @include flexColumn(center, center);
  @include for-notebook{
    overflow-x: auto
  }
}

.sectionTable {
  @include widthAndHeight(100%, 100%);
}

.sectionTableDetails {
  @include widthAndHeight(100%, 40px);
  @include display(center, center);
  border: 1px solid $g-1;
  background-color: $w;
  bottom: 0;
}

.sectionTableDetails {
  line-height: 42px;
  font-size: 1.2rem;
  padding-top: 0;
}

.checkBoxListTable {
  @include widthAndHeight(100%, 50px);
  @include display(flex-end,center);

}

.checkBoxListTable label span {
  font-size: 1rem;
}


.icon {
  @include display(center, center);
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;

  &:hover {
    border: 1px solid $g-1;
    background-color: $ButtonsActionsTableBackgroundColor;
    cursor: pointer;
  }
}
.iconRestore {
  @include widthAndHeight(34px, 34px);
}