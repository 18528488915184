@import "../../styles/style";

.checkbox {
  &-controller {
    height: 45px;
    @include display(flex-start, center);
    white-space: nowrap;
  }
  &-recstatus {
    height: 40px;
    @include display(flex-start, center);
    white-space: nowrap;
  }
}

.fields {
  @include widthAndHeight(100%, 80px);
  margin-bottom: x;
  div div input::placeholder{
    color: rgb(112, 112, 112);

  }
}


.field-119::placeholder{  color: rgb(112, 112, 112);
}

.textarea div div textarea{
  @include widthAndHeight(100%, 100%);
  border: 1.2px solid #ccc;
  font-size: 1rem;
  resize: none;

  &:focus {
    outline: $w;
    border-color: $w;
    box-shadow: 0 0 10px $w;
  }
}

