@import "../../../../../../../styles/mixins";

.content {
  @include widthAndHeight(100%, calc(100vh - 51px));
  @include display(center, flex-start);
  flex-wrap: wrap;
  background-color: $w;
  overflow-y: auto;
  padding: 0 32px;
  &_form {
    @include widthAndHeight(100%, 90%);
    @include flexColumn(space-between, "");

    &-error{
      font-size: 0.9rem;
      color:#b65257;
      height: 18px;

    }

    &-header {
      @include widthAndHeight(100%, 296px);
      @include flexColumn(space-between, flex-start);
      &:first-child {
        margin-top: 12px;
      }
    }

    &-list-checkbox{
      @include widthAndHeight(58%, 80px);
      @include display(space-between, flex-end);
      margin-top: 12px;
      margin-bottom: 12px;
      min-width: 700px;
    }

    &-group-checkbox{
      @include widthAndHeight(260px, 100%);
      @include flexColumn(space-between, flex-start);

      &-sm{
        @include widthAndHeight(260px, 50px);
        @include flexColumn(space-between, flex-start);

      }
    }
    &-section{
      width: 100%;
      margin-bottom: 16px;
      &-inputs{
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}

.textarea div div textarea{
  resize: none;
  &::placeholder{
    @include placeholder();
  }
}


.checkbox-error{
  background-color: rgb(238, 154, 154);
}
.checkbox{
background-color: transparent;}