@import "../../../styles/style";

@mixin button-size($size) {
  @include widthAndHeight(180px, 45px);
  @include font(1.2rem, 300);
  border-radius: 16px;
  border: none;
  cursor: pointer;
  letter-spacing:0.6px;

  @if ($size == "sm") {
    @include widthAndHeight(140px, 45px);
    @include font(1.1rem, 300);
    @include display(flex-end,center);
  }
}

@mixin button-type($type) {
  @if ($type == "save") {
    background-color: $y;
    &:hover {
      background-color: $y-hover;
    }
  } @else {
    @include colors($g-3, $w);
    &:hover {
      background-color: $g-hover;
    }
  }
}

@mixin button($type, $size) {
  @include button-size($size);
  @include button-type($type);
}

.button-save {
  @include button("save", "");
}

.button-save-sm {
  @include button("save", "sm");
}

.button-cancel {
  @include button("cancel", "");
}

.button-cancel-sm {
  @include button("cancel", "sm");
}

.button_icon{
  @include widthAndHeight(25px, 25px);
  margin: 0px 12px 0px 8px;
}
