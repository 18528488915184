@import "../../../styles/style";

.section {
  @include widthAndHeight(100%, 100%);
}
.section_inline-content {
  @include widthAndHeight(100%, 15%);
}

.section_content-label {
  @include font(0.9rem, 600);
}
.section_content-label-inline {
  @include font(1.3rem, 400);
  letter-spacing: 0.6px;
}

.section,
.section_inline-content {
  @include flexColumn(space-between, flex-start);
  position: relative;

  &_content {
    @include widthAndHeight(100%, 30%);
    display: flex;
    position: relative;

    &-label,
    &-label-inline {
      padding-bottom: 4px;
      color: $g-2;
    }

    &-input-inline,
    &-input {
      @include colors(transparent, $g-2);

      &:hover {
        cursor: text;
      }
      &:focus {
        @include resetFocusInput();
      }
    }

    &-input-inline {
      border: none;
      border-bottom: 1.5px solid $g-1;
      @include widthAndHeight(100%, 38px);
      @include font(1.1rem, 300);

      &:focus {
        border-bottom: 1.5px solid $y;
      }
    }
    &-input {
      border: 1.5px solid $g-1;
      background-color: $w;
      @include widthAndHeight(100%, 40px);
      @include font(1rem, 300);

      &:focus {
        border: 1.5px solid $y;
      }
    }
    &-visibility {
      @include display(center, center);
      border: none;
      background-color: transparent;
      position: absolute;
      right: 4px;
      bottom: -20px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

