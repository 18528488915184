@import "../../../styles/style";

.contentApp {
  @include widthAndHeight(calc(100% - 250px), 100%);
  @include display(center, center);
  position: absolute;
  bottom: 0;

  &--img {
    opacity: 0.3;
  }
}
