@import "../../../../../../../styles/style";
@import "../forms.module.scss";

.content{
  width: 100%;
  padding: 0 12px;
}
.form-row{
  
  @include formRow();
  &-checkbox{
    @include formRow(true);

  }
}