@import "../../styles/style";

.content {
  @include widthAndHeight(100%, auto);
  @include flexColumn(center, center);
  margin: auto;
  margin-top: 4px;
  @include for-notebook {
    margin-top: 8px;
  }

  &_expander {
    @include widthAndHeight(100%, auto);
    
  }

  &_header {
    @include widthAndHeight(100%, 64px);
    @include display(space-between, center);
    background-color: $w;
    cursor: pointer;
    @include for-notebook {
      width: 100%;
    }

    &-border,
    &-border-close {
      @include widthAndHeight(100%, 64px);
      @include display(space-between, center);
      padding: 0 26px;
      border: 1px solid $b;
      border-bottom: none;
    }

    &-border-close {
      border-bottom: 1px solid $b;
    }
    &-name {
      @include font(1.4rem, 300);
      @include display(space-between, center);
      height: 60px;
      white-space: nowrap;
    }

    &-button,
    &-button-open {
      @include widthAndHeight(100px, 40px);
      @include display(space-between, center);
      cursor: pointer;
      border-radius: 8px;
      border: none;
      padding: 8px;
      background-color: $g-3;
      color: $w;
      z-index: 100;
      &-open {
        background-color: $g-4;
      }
      span {
        @include font(1.1rem, 300);
        padding-left: 4px;
      }
      img {
        @include widthAndHeight(30px, 30px);
        
        display: "";
      }
      &:hover{
        background-color: $g-4;
      }
    }
  }
  &_section {
    @include widthAndHeight(100%, auto);
    margin: auto;
    border: 1px solid $b;
    border-top: none;
    padding: 0 12px;
    background-color: $w;
    white-space: nowrap;

    @include for-notebook {
      overflow-x: auto;
      @include widthAndHeight(100%, auto);
    }
  }
}

.icon {
  &-success,
  &-error {
    @include widthAndHeight(24px, 24px);
    padding: 2px;
    border-radius: 50%;
    margin-left: 16px;

  }
  &-success {
    background-color: $CardSuccess;
  }
  &-error {
    background-color: $CardError;
  }
}

@media screen and (max-width: 1200px) {
  .form {
    @include widthAndHeight(100%, 100%);
    min-height: 100px;
    @include flexColumn(space-between, "");
  }

  .rowFormGroup {
    @include widthAndHeight(100%, 280px);
  }
  .rowFormGroup2 {
    @include widthAndHeight(100%, 182.4px);
  }
}
