@import "../../../../styles/style";

.config {
  &_search {
    height: 80px;
    @include display(center, center);
  }
  &_section {
    @include widthAndHeight(100%, calc(100vh - 120px));
  }
  &_content {
    @include widthAndHeight(100%, 90%);
    @include display(center, center);
    margin: auto;
    &-list {
      margin-top: 12px;
      @include widthAndHeight(100%, 100%);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      overflow: auto;
      gap: 4px;
      padding: 0 32px;
    }
  }

  &_group {
    @include widthAndHeight(auto, 260px);
    min-width: 300px;

    list-style: none;
    &-title {
      width: 92%;
      @include font(1rem, 500);
      color: $g-1;
      border-bottom: 2px solid $g-5;
      padding: 4px 0px;
    }
    &-item {
      height: 36px;
      border-right: 4px solid transparent;
      @include display(flex-start, center);
      width: 100%;

      &:hover {
        cursor: pointer;
        color: $y;
      }
    }
  }
}
