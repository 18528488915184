@import "../../../../styles/style";

.title {
  @include widthAndHeight(100%, 80px);
  @include display(flex-start, center);
  margin: auto;
  
  &-form {
    font-size: 2.5rem;
    font-weight: 600;
  }
}
