@import "../../../../../../styles/style";

.content {
  @include widthAndHeight(100%, 100%);
  margin: auto;
  background-color: $w;
 
  @include for-notebook{
    padding: 0 8px;
  }
}

.checkBox {
  @include widthAndHeight(100%, 48px);
  @include display(flex-start, center);
}

.checkBoxGroup {
  @include widthAndHeight(95%, 50px);
  @include display(flex-start, center);
}

.checkBoxList {
  @include display(space-around, center);
}

.section {
  @include flexColumn(space-between, center);
  margin: auto;
}



.title {
  @include widthAndHeight(120px, 100%);
  @include display(flex-start, center);
  @include font(2.6rem, 600);
}

.form {
  @include widthAndHeight(100%, 100%);
  @include flexColumn(space-between, "");
}

.rowInputGroup {
  @include display(space-between, flex-start);
  @include for-notebook{
    @include flexColumn(space-between, "");
  }

  width: 100%;
}

.inputLarge {
  width: 49.5%;
  @include for-notebook{
    @include flexColumn(space-between, "");
    width: 100%;
  }
}

.inputMiddle {
  width: 24.2%;
  padding: 0;

  @include for-notebook{
    width: 100%;
  }

}

.rowForm {
  @include display(space-between, "");
  width: 100%;
}



