@import "../../styles/style";

.contain {
  @include backgroundImage("../../assets/Fundo.png");
  @include widthAndHeight(100vw, 100vh);
  @include display(center, center);
}

.section {
  @include widthAndHeight(480px, 380px);
  background-color: $g-6;
  border-radius: 16px;

  &_header {
    @include widthAndHeight(100%, 80px);
    @include display(center, center);

    &-img {
      @include widthAndHeight(200px, 60px);
      margin: auto;
    }
  }

  &_form{

    @include widthAndHeight(80%, 250px);
    @include flexColumn(space-between, center);
    
    margin: auto;
    &-content{
      @include flexColumn(space-around, center);

      @include widthAndHeight(100%,140px);

    }

    &-input{
      @include widthAndHeight(100%,68px);
      @include flexColumn(space-between,flex-start);
      label{
        @include font(1.3rem, 400);
        letter-spacing: 0.6px;

        padding-bottom: 4px;
        color: $g-2;
      }
      input{
        @include colors(transparent, $g-2);

        border: none;
        border-bottom: 1.5px solid $g-1;
        @include widthAndHeight(100%, 38px);
        @include font(1.1rem, 300);
  
        &:focus {
          border-bottom: 1.5px solid $y;
          @include resetFocusInput();

        }
      }
    }

 
    &-button{
      @include widthAndHeight(240px, 50px);
      @include font(1.6rem,600);
      letter-spacing: 0.9px;
      background-color: $y;
      color: $g-0;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      &-hover{
        background-color: $y-hover;

      }
    }
  
  }
  &_footer{
    @include widthAndHeight(100%, 50px);
    @include display(center, center);
    text-align: center;
    @include font(0.9rem,300);
    
  }
}

