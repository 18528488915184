@import"../../styles/style";

@mixin card() {
  @include display(space-around, center);
  @include widthAndHeight(320px, 120px);
  position: absolute;
  float: right;
  top: 5vh;
  right: 5vw;
  margin: auto;
  border-radius: 12px;
  z-index: 2;
  cursor: pointer;
}

@keyframes animateCard {
  0%,
  100% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

.card {

  &_icon {
    @include widthAndHeight(60px, 60px);
    padding: 4px;
    margin-left: 8px;
    animation: animateCard 5s alternate;
  }

  &_text {
    @include flexColumn(center, center);
    @include widthAndHeight(80%, 60%);
    text-align: center;
    margin: auto;
    padding: 8px;
    animation: animateCard 5s alternate;

    &-title {
      @include font(1.4rem, 700);
      color: $w;
    }

    &-message {
      @include font(1.2rem, 300);
      color: $w;
      text-align: center;
    }
  }
  

}


.card-success {
  @include colors($CardSuccess, $CardMessage);
  @include card()
}

.card-error {
  @include colors($CardError, $CardMessage);
  @include card()
}

.card-info {
  @include colors($CardInfo, $CardMessage);
  @include card()
}

.card-warning {
  @include colors($CardWarning, $CardMessage);
  @include card()
}
