@import "../../../styles/style";

.section {
  @include widthAndHeight(100%, 100%);
  @include flexColumn(flex-start, center);
  margin: auto;
  position: relative;
}

.table {
  @include widthAndHeight(100%, 100%);
  @include flexColumn(center, center);
  overflow: auto;
}

.sectionTable {
  @include widthAndHeight(100%, 100%);
}

.sectionTableDetails {
  @include widthAndHeight(100%, 40px);
  @include display(center, center);
  border: 1px solid $g-1;
  background-color: $w;
  bottom: 0;
}

.sectionTableDetails {
  line-height: 42px;
  font-size: 1.2rem;
  padding-top: 0;
}

.checkBoxListTable {
  @include widthAndHeight(100%, 30px);
  display: flex;
  justify-content: flex-end;
}

.checkBoxListTable label span {
  font-size: 1rem;
}
