@import "../../../../../../../styles/style";
.form-row{
    @include widthAndHeight(100%,80px);
    @include display(space-between,center);
    gap:16px;
  
}

.field-152::placeholder {
    color: rgb(112, 112, 112);
  }