@import "../../styles/style";
@mixin btn-modal {
  @include widthAndHeight(140px, 40px);
  border: none;
  border-radius: 12px;
  @include font(1rem, 400);
  &:hover {
    cursor: pointer;
  }
}

.background-modal {
  @include widthAndHeight(100%, 100%);
  @include display(center, center);
  background-color: $background-modal;
}

.section {
  @include widthAndHeight(30%, 60%);
  @include display(center, center);
  min-height: 260px;
  min-width: 560px;
  background-color: $g-1;
  border-radius: 16px;
  box-shadow: 1px 1px 12px 6px #727272;
  &_content {
    @include widthAndHeight(60%, 68%);
    @include flexColumn(space-between, center);
    min-height: 160px;
    &-title {
      @include font(1.6rem, 600);
      color: $w;
    }
    &-message {
      @include font(1.4rem, 300);
      width: 100%;
      text-align: center;
      color: $w;
    }
  }
  &_footer {
    @include widthAndHeight(95%, 40px);
    @include display(space-between, center);
    &-btn-cancel {
      @include btn-modal();
    }
    &-btn-delete {
      @include btn-modal();
      background-color: $y;
      &:hover {
        background-color: $y-hover;
      }
    }
  }
}
