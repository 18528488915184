@import "../../../styles/style";
.icon {
    @include widthAndHeight(40px, 40px);
    @include display(center, center);
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 5px;
  
    &:hover {
      border: 1px solid $g-1;
      background-color: $ButtonsActionsTableBackgroundColor;
      cursor: pointer;
    }
  }
  .iconFilter {
    @include widthAndHeight(22px, 22px);
  }
  
  .iconAdd {
    @include widthAndHeight(35px, 35px);
  }
  
  .iconEdit,
  .iconCopy {
    @include widthAndHeight(30px, 30px);
  }
  
  .iconRemove {
    @include widthAndHeight(32px, 32px);
  }
  