@import "../../../../../../../styles/style";
.form-row {
  @include widthAndHeight(600px, 80px);
  @include display(space-between, top);
  gap: 16px;
  margin: auto;
  &-header {
    @include widthAndHeight(600px, 100px);

    margin: auto;
    &-input {
      padding: 4px 0px;
    }
    &-input,
    &-label {
      @include widthAndHeight(100%, 100%);
    }
  }
}

.span-label {
  @include widthAndHeight(200px, 40px);
  @include display(flex-start, center);
  @include font(1rem, 500);
}

.input {
  &-sm {
    width: 100px;
  }
  &-middle {
    width: 40%;
  }

  &-large {
    width: 64%;
  }
}

.button-form-edit {
  @include widthAndHeight(100px, 40px);
  z-index: 8;
  border: none;
  background-color: $g-2;
  color: $w;
  &:hover {
    cursor: pointer;
    background-color: $g-4;
  }
}

.field-152::placeholder {
  color: rgb(112, 112, 112);
}

.table {
  padding: 0 8px;
  &_header {
    @include display(space-between, center);
    width: 30%;
    margin: auto;
    border: 1px solid $g-3;
    padding: 2px 8px;
    &-off {
      display: none;
    }
  }
}

.money-show {
  @include display(center, center);
  padding-top: 4px;
  @include font(1rem, 400);
}
.result {
  @include widthAndHeight(240px, 40px);
  @include display(flex-start, center);
  border: 1px solid #2f2f2f;
  &_calc {
    @include widthAndHeight(100%, 100%);
    @include display(flex-start, center);
    @include font(1rem, 400);
    padding-left: 8px;
    &-prefix {
      background: rgb(244, 244, 244);
      color: rgb(40, 40, 40);
      @include widthAndHeight(32px, 100%);
      @include font(1rem, 400);
      @include display(center, center);
    }
  }
}
