@import "../../../../../../../styles/style";

.content{
    @include widthAndHeight(100%,calc(100% - 50px));
    overflow: auto;
    padding: 0 24px;
 
}

.section{
    @include widthAndHeight(100%,auto);
    @include flexColumn(space-around,center);
    &_form{
        @include widthAndHeight(100%,100%);
        &-row{
            &-sm{
            width: 600px;
            @include display(space-between,center);
            gap:16px;
        }
            &-full{
            width: 100%;
            @include display(space-between,flex-start);
            gap:16px;
        }

            &-footer{
            width: 100%;
            @include display(space-between,"");
            gap: 16px;
        }
        &-checkbox{
            height: 65px;
            @include display(flex-start,flex-end);

        }
    }}
}

.input-large{
    width: 65%;
}

.input-sm{
    width: 25%;
}

.field-152::placeholder {
    color: rgb(112, 112, 112);
  }