@import "../../../../styles/style";

.title_box {
  background-color: $g-3;
  @include widthAndHeight(100%, 40px);
  @include display(flex-start, center);
  text-transform: uppercase;

  margin-top: 16px;
  margin-bottom: 16px;
  &-title {
    @include colors($g-3, $w);

    font-size: 1rem;
    font-weight: 500;
    padding: 0 12px;
  }
}
