@import "../../../../styles/style";

.header__contentApp {
  @include widthAndHeight(auto, 50px);
  @include display(center, center);

  border: 0.5px solid $g-5;
  box-shadow: 1px -1px 5px 0.5px $g-5;
  background-color: $w;

  &--tabs {
    @include widthAndHeight(90%, 100%);
    @include display(flex-start, center);
  }

  &--tab {
    @include widthAndHeight(calc(90% / 4), 100%);
    @include display(center, center);
    border-right: 1px solid $g-5;
    position: relative;
    background-color: $w;
    white-space: nowrap;

    &:hover {
      .header__tab--iconClose {
        opacity: 1;
        cursor: pointer;
      }
    }

    &:hover::after {
      opacity: 1;
    }

    &::after {
      @include colors($g-4, $w);
      @include font(1rem, 400);
      top: 55px;
      text-align: center;
      white-space: nowrap;
      pointer-events: none;
      transition: 0.5s;
      opacity: 0;
      content: attr(aria-label);
      border-radius: 5px;
      padding: 3px 4px;
      position: absolute;
      z-index: 8;
      border: 2px solid $w;

    }
  }
}

.header__tab {
  &--link {
    @include widthAndHeight(100%, 100%);
    @include display(center, center);
    z-index: 1;
  }

  &--name {
    @include font(1rem, 400);
    color: $g-1;
    width: 60%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: auto;
  }

  &--selected {
    @include colors($y, $g-1);
  }
}

.header__tab--iconClose {
  position: absolute;
  right: 2px;
  opacity: 0;
  z-index: 8;
  &:hover {
    @include widthAndHeight(30px, 30px);
  }
}

.header__buttons {
  @include widthAndHeight(10%, 100%);
  @include display(space-between, center);

  &--tabs,
  &--off,
  &--userInformation {
    @include widthAndHeight(50%, 100%);
    background-color: transparent;
    border: 1px solid $g-5;
    cursor: pointer;
    &:hover {
      background-color: $y;
    }
  }
  &--tabs {
    @include font(1.4rem, 400);
    position: relative;
    background-color: $y;
    color: $g-3;
  }

  &--off {
    &:hover {
      background-color: $w;
      cursor: auto;
    }
  }
}

.tabs__minimized {
  @include widthAndHeight(300px, auto);
  @include font(1rem, 400);
  color: $g-1;
  top: 50px;
  right: 0;
  position: absolute;
  text-decoration: none;
  z-index: 2;

  &--hide {
    display: none;
  }

  li {
    @include widthAndHeight(100%, 40px);
    @include display(center, center);
    text-align: start;
    list-style: none;
    border: 0.5px solid $g-5;

    &:hover {
      .header__tab--iconClose {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

.tab__minimized--link {
  @include widthAndHeight(100%, 100%);
  @include display(flex-start, center);
  padding: 8px 4px;
}

.header__buttons--off {
  color: $w;
  border: none;
}
