@import "../../styles/style";
.header {
  &_content {
    @include widthAndHeight(100%, 100%);

    &-search {
      height: 100px;

      width: 100%;
      @include display(space-between, center);
    }

    &-left {
      height: 100px;

      width: 100%;
      @include display(left, center);
    }
  }
}
