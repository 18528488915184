.content {
  width: 100%;
}

.calendar {
  width: 100%;
  &-error {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
  }
}
.calendar div div div div {
  line-height: 38px;
}
