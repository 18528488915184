@import "../../../styles/style";

.header {
  @include flexColumn(center, center);
  @include widthAndHeight(100%, 20%);
  min-height: 160px;
  text-align: center;
  position: relative;
  z-index: 0;
  border-bottom: 1px solid $g-5;
  position: relative;
  &-icon {
    position: absolute;
    top: 2px;
    right: 1px;
    cursor: pointer;
    img {
      @include widthAndHeight(26px, 26px);
    }
  }
  &_img {
    &-close {
      @include widthAndHeight(40px, 40px);
    }
    &-open {
      @include widthAndHeight(50px, 50px);
    }
  }
}

.header_content {
  @include flexColumn(space-around, center);

  &-name {
    @include font(1rem, 500);
    color: $g-3;
    padding-top: 16px;
  }
  &-title {
    @include font(0.95rem, 300);
    color: $g-3;
  }
}
