@import "../../../../../../styles/style";
.section {
  position: relative;
}

.inputFullWidth {
  @include widthAndHeight(100%, 80px);
}
.sectionContent {
  @include widthAndHeight(100%, calc(100vh - 220px));
  padding: 0 42px;
  margin: auto;

  &-form {
    @include widthAndHeight(100%, 60px);
    @include flexColumn(space-between, flex-start);
  }
  form {
    @include widthAndHeight(100%, 100%);
  }
}
