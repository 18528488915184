@import "../../../styles/style";

@mixin btn-content() {
  @include display(flex-end, center);
  @include widthAndHeight(100%, 100%);
  background-color: $w;
}

@mixin btn-content-close() {
  height: 40px;

  @include display(space-between, center);

  &:hover::after {
    opacity: 1;
  }

  &::after {
    @include colors($g-4, $w);
    @include font(1rem, 400);
    white-space: nowrap;
    pointer-events: none;
    transition: 0.5s;
    opacity: 0;
    content: attr(aria-label);
    border-radius: 5px;
    padding: 3px 4px;
    position: absolute;
    left: 55px;
    z-index: 18;
    border: 2px solid $w;
    margin-left: 12px;
  }
}

@mixin btn-content-open() {
  height: 40px;
  &:hover::after {
    opacity: 0;
  }
}

.button_content {
  &-close {
    @include btn-content();
    @include btn-content-close();
    button {
      padding: 0px;
      min-width: 0px;
      border: none;
      border-bottom: 0.5px solid $g-5;
      span i {
        display: none;
      }
    }
  }

  &-open {
    @include btn-content();
    @include btn-content-open();

    button {
      min-width: none;
      @include widthAndHeight(100%, 100%);
      border-radius: none;
      border: none;
      border-bottom: 0.5px solid $g-5;
      span i {
        display: none;
      }
    }
  }
}

@mixin btn-nav() {
  @include widthAndHeight(100%, 95%);
  @include display(flex-start, center);
  @include font(1.25rem, 300);
  color: $g-3;
  border: none;
  padding: 0;
}

.button_nav {
  @include btn-nav();

  &-close {
    @include btn-nav();
    justify-content: center;
  }

  &:hover {
    border-right: 4px solid $y;
  }
}

.button_options {
  @include widthAndHeight(100%, 100%);
  @include display(flex-start, center);
  background-color: transparent;
  border: none;

  &-icon {
    @include widthAndHeight(24px, 24px);
  }

  &-name {
    @include font(1rem, 400);
    margin-left: 8px;
    color: $g-3;
    white-space: nowrap;
  }

  &-close {
    @include widthAndHeight(100%, 100%);
    background-color: transparent;
    border: none;

    @include display(center, center);
  }

  &:hover {
    cursor: pointer;
  }
}

.flexContainer-98 {
  display: unset;
}

.ms-ContextualMenu-list {
  li {
    @include font(1.2rem, 400);
    width: auto;
    &:hover {
      border-right: 5px solid $y;
    }
  }
}

.ms-Button-flexContainer,
.flexContainer-101 {
  width: 95%;
}

.button_not-options {
  @include display(flex-end, center);
  @include widthAndHeight(100%, 40px);
  border-bottom: 0.5px solid $g-5;

  background-color: $w;
  &:hover {
    background-color: $hover-button-menu;
  }
  &-content {
    @include widthAndHeight(100%, 100%);
    @include display(flex-start, center);
    @include font(1.2rem, 400);

    outline: transparent;
    position: relative;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    padding: 0px 16px;

    color: $g-2;
    user-select: none;
  }

  &-close {
    @include btn-content-close();
    padding: 3px 4px;
    border-bottom: 0.5px solid $g-5;

    @include widthAndHeight(100%, 40px);
    @include display(center, center);
    @include font(1.2rem, 400);

    outline: transparent;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    color: $g-2;
    user-select: none;
    &-content {
      position: none;
    }
    &:hover {
      cursor: pointer;
      background-color: $hover-button-menu;
    }
  }
}
